import request from "@/utils/request"

//用户反馈数据一览
export function feedBackPage(data) {
    return request({
      url: '/serverApiUser/feedBackPage',
      method: 'post',
      data
    })
  }
//banner全部
export function carouselPage(data) {
  return request({
    url: '/weChatCarousel/carouselPage',
    method: 'post',
    data
  })
}
//新增banner
export function addinsert(data) {
  return request({
    url: '/weChatCarousel/insert',
    method: 'post',
    data
  })
}
//编辑banner
export function updatebanner(data) {
  return request({
    url: '/weChatCarousel/update',
    method: 'post',
    data
  })
}
//banner详情
export function getCarouselDetail(query) {
  return request({
    url: '/weChatCarousel/getCarouselDetail',
    method: 'get',
    params:query
  })
}
//冻结banner
export function carouselDelete(query) {
  return request({
    url: '/weChatCarousel/carouselDelete',
    method: 'get',
    params:query
  })
}
//标签管理一览
export function labelPage(data) {
  return request({
    url: '/label/labelPage',
    method: 'post',
    data
  })
}
//新增标签组
export function addlabel(data) {
  return request({
    url: '/label/insertLabel',
    method: 'post',
    data
  })
}
//编辑标签组
export function updateLabel(data) {
  return request({
    url: '/label/updateLabel',
    method: 'post',
    data
  })
}
//标签组数据回显（标签组基础数据+一级标签）
export function selectLabelDetail(query) {
  return request({
    url: '/label/selectLabelDetail',
    method: 'get',
    params:query
  })
}
//根据上级标签的id查询出下级标签集合
export function getLabelList(query) {
  return request({
    url: '/label/getLabelList',
    method: 'get',
    params:query
  })
}
//问卷收集一览
export function questionnaireCollectPage(data) {
  return request({
    url: '/questionnaireCollect/questionnaireCollectPage',
    method: 'post',
    data
  })
}

//用户填写反馈详情
export function getActivityFeedBackDetail(query) {
  return request({
    url: '/weChatCarousel/getActivityFeedBackDetail',
    method: 'get',
    params:query
  })
}
//批量导出活动反馈数据
export function exportExcel(data) {
  return request({
    url: '/weChatCarousel/exportExcel',
    method: 'post',
    responseType: "blob",
    data
  })
}
//查询所有的标签组类别数据
export function getGroupLabel() {
  return request({
    url: '/label/getGroupLabel',
    method: 'get',
   
  })
}
//根据标签组名称模糊查询所有标签组
export function getAssociateGroupLabel(query) {
  return request({
    url: '/label/getAssociateGroupLabel',
    method: 'get',
    params:query
  })
}
//
export function getAssociateLabel(query) {
  return request({
    url: '/label/getAssociateLabel',
    method: 'get',
    params:query
  })
}
//激活banner
export function carouselActivate(query) {
  return request({
    url: '/weChatCarousel/carouselActivate',
    method: 'get',
    params:query
  })
}
//查询全部(智选一览)
export function bannerSmartList(query) {
  return request({
    url: '/bannerSmart/listPage',
    method: 'get',
    params:query
  })
}
//智选新增
export function bannerSmartsave(data) {
  return request({
    url: '/bannerSmart/save',
    method: 'post',
   data
  })
}
//智选修改
export function bannerSmartupdate(data) {
  return request({
    url: '/bannerSmart/update',
    method: 'post',
   data
  })
}
//智选banner冻结
export function notFreeze(query) {
  return request({
    url: '/bannerSmart/notFreeze',
    method: 'get',
    params:query
  })
}
//智选banner详情
export function SmartlistById(query) {
  return request({
    url: '/bannerSmart/listById',
    method: 'get',
    params:query
  })
}
//长沙市企业数字化转型需求调查问卷
export function selectQuestionnaireById(query) {
  return request({
    url: '/questionnaireCollect/selectQuestionnaireById',
    method: 'get',
    params:query
  })
}
//文件收集信息导出
export function questionnaireDataExport(data) {
  return request({
    url: '/questionnaireCollect/questionnaireDataExport',
    method: 'post',
    responseType: "blob",
    data
  })
}








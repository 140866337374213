<template>
  <div>
    <div class="container">
      <el-card style="margin-top: 20px">
        <el-form ref="form" :model="queryInfo" label-width="100px">
          <div class="form">
            <div class="comble_txt">
              <el-form-item label="问卷类型：" prop="">
                <el-radio-group v-model="queryInfo.questionnaireType" @change="changeSearchangeSearch">
                  <el-radio-button
                    label=""
                  >全部</el-radio-button >
                  <el-radio-button
                    label="长沙市数智化转型需求调查问卷"

                  >长沙市数智化转型需求调查问卷</el-radio-button
                  >
                  <el-radio-button
                    label="转型标杆问卷"

                  >转型标杆问卷</el-radio-button
                  >

                </el-radio-group>
              </el-form-item>

            </div>
          </div>
        </el-form>
        <div class="input-with">
          <el-button type="primary" @click="exportExcel">批量导出</el-button>
        </div>
        <div style="float: right">
          <!-- <div class="seach_box">
            <el-input
              placeholder="请输入名称"
              v-model="queryInfo.queryConditions"
              class="input-with-select"
            >
              <el-button class="seach" slot="append" @click="search()">搜索</el-button>
            </el-input>
          </div> -->
        </div>
        <el-table
          v-loading="loading"
          element-loading-text="加载中..."
          element-loading-spinner="el-icon-loading"
          :data="listData"
          stripe
          ref="tableRef"
          style="width: 100%"
          class="in_table"
          :row-key="getRowKey"
          @sort-change="changeSort"
          @selection-change="handleSelectionChange"
          :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
        >
          <el-table-column type="selection" width="55" align="center" :reserve-selection="true" />

          <el-table-column label="编号" align="center" prop="id"></el-table-column>

          <el-table-column label="提交人" prop="linkman" align="center"></el-table-column>
          <el-table-column label="职位" align="center">
            <template v-slot="{ row }">
              <span>{{ row.position }}</span>
            </template>
          </el-table-column>

          <el-table-column label="联系电话" prop="concatPhone" align="center">
            <template v-slot="{ row }">
              <span>{{ row.concatPhone }}</span>
            </template>
          </el-table-column>
          <el-table-column label="公司全称" prop="content" align="center">
            <template v-slot="{ row }">
              <span>{{ row.companyFullName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="所用时间" prop="time" align="center">
            <template v-slot="{ row }">
              <span v-if="row.time">{{ row.time }}s</span>
            </template>
          </el-table-column>
          <el-table-column label="问卷类型" width="200"  prop="questionnaireType" align="center">
            <template v-slot="{ row }">
              <span style="color:#33b772 ;background: #cceddc;" class="quertion_type" v-if="row.questionnaireType=='转型标杆问卷'">{{ row.questionnaireType }}</span>
              <span v-else class="quertion_type">{{ row.questionnaireType }}</span>
            </template>
          </el-table-column>
          <el-table-column label="提交时间" sortable prop="createTime" align="center">
            <template v-slot="{ row }">
              <div>{{ row.createTime | date }}</div>
              <div>{{ row.createTime | time }}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" prop="createTime" align="center">
            <template v-slot="{ row }">
              <span class="edit" @click="dialoginfo(row)">详情</span>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>

    <div class="new_page">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-dialog title="详情" :visible.sync="dialogVisible" width="687px" class="account_dialog">
      <div class="dialogVisible">
        <div class="dialogVisible_top">
          <div class="username_phone">
            <span>{{ feedinfo.linkman }}</span>
            <span style="margin-left: 10px;">{{ feedinfo.concatPhone }}</span>
          </div>
          <div class="username_phone">
            <span>{{ feedinfo.companyFullName }}</span>
            <span class="feed_center"></span>
            <span>{{ feedinfo.region }}</span>
          </div>
        </div>
        <div class="feed_info">
          <div
            class="feed_info_cont"
            v-for="(item, index) in feedinfo.answerResultList"
            :key="index"
          >
            <div class="caption">
              <span>{{ index+1 }}、{{ item.question }}</span>
            </div>
            <div class="feed_info">
          <div class="feed_info_cont" >

            <div class="answer">

              <div>
               <div v-if="item.questionType==9||item.questionType==3">
                <div style="padding: 5px 0px;" v-for="(el, indexs) in item.answerList" :key="indexs">
                  <div v-if="indexs==0">
                    <span class="order_num">{{ indexs+1 }}</span>
                  <span>{{ el }}</span>
                  </div>
                  <div   style="padding: 10px;min-height: 70px;" class="did_not" v-else>
                    <span>{{ el }}</span>
                  </div>
                </div>
               </div>
               <div v-else>
                <div style="padding: 5px 0px;display:flex" v-for="(el, indexs) in item.answerList" :key="indexs">
                  <span class="order_num">{{ indexs+1 }}</span>
                 <div v-if="item.answerList[indexs].length==2">
                  <span>其他</span>
                  <div style="padding: 10px;min-height: 70px;" class="did_not" >
                    <span>{{ item.answerList[indexs][1]}}</span>
                  </div>
                 </div>

                  <div v-else >
                    <span >{{ el }}</span>
                  </div>
                </div>
               </div>
                  <div v-if="item.allAnswerList" class="did_not">
                    <div class="option">
                      <span>未选择选项</span>
                    </div>
                    <div style="padding: 5px 0px;display:flex;" v-for="(o,indexs1) in item.allAnswerList" :key="indexs1">
                    <span class="not_num">{{ indexs1+1 }}</span>
                    <span>{{ o }}</span>
                    </div>
                  </div>
              </div>

            </div>
          </div>
        </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { questionnaireCollectPage, selectQuestionnaireById,questionnaireDataExport } from '@/api/system'
import { pagination } from '@/mixins/pagination'
const defaultQueryInfo = Object.freeze({
  orderByParam: '',
  questionnaireType: '',
  pageNum: 1,
  pageSize: 10
})
export default {
  name: 'Diagnosisrecord',
  mixins: [pagination],
  data() {
    return {
      queryInfo: { ...defaultQueryInfo },
      listData: [],
      // 遮罩层
      loading: true,
      ids:[],
      // 总条数
      total: 0,
      dialogVisible: false, //详情\
      feedinfo: {}
    }
  },
  created() {
    this.search()
  },
  methods: {
    //查询一览数据
    async search() {
      this.loading = true
      const { data: res } = await questionnaireCollectPage(this.queryInfo)
      if (res.resultCode === 200) {
        this.listData = res.data.list
        this.total = res.data.total
        this.loading = false
      }
    },
      //批量导出
   async exportExcel(){
    // if(this.ids.length){
    //     this.$message.error('请勾选需要导出的问卷！')
    //     return
    //   }
        this.loading=true

        const res = await questionnaireDataExport(this.ids)
        if (res.data) {
          this.loading=false
          let blob = new Blob([res.data])
          let href = window.URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
          const a = document.createElement('a') //创建a标签
          a.style.display = 'none'
          console.log(href);
          a.href = href // 指定下载'链接
          let nowdata = new Date();
        let year = nowdata.getFullYear();
        let month = nowdata.getMonth() + 1;
        let date = nowdata.getDate();
        let nowtime = year + "-";
        if (month < 10) {
            nowtime += "0";
        }
        nowtime += month + "-";
        if (date < 10) {
            nowtime += "0";
        }
        nowtime += date;
          a.download = `长沙市数智化转型需求调查问卷${nowtime}.xlsx` //指定下载文件名
          // a.download = "test.png";
          a.click() //触发下载
          window.URL.revokeObjectURL(a.href) //释放URL对象
          if (document.body.contains(a)) {
            document.body.removeChild(a) //释放标签
          }
          this.$refs.tableRef.clearSelection()
          this.search()
          this.isIndeterminate = false;
          this.checkAll=false
        } else {
          this.$message.error('下载失败!')

      }
    },
      //多选
      handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id)

    },
    //详情弹窗
    async dialoginfo(row) {
      this.dialogVisible = true
    this.feedinfo={}
      const query = {
        id: row.id
      }
      const { data: res } = await selectQuestionnaireById(query)
      if (res.resultCode === 200) {
        this.feedinfo = res.data
        this.feedinfo.answerResultList.forEach((el,index) => {

            if(el.questionType==6){
              el.answerList.forEach((o,i)=>{
                if( el.answerList[i]=='其他'){

                  el.answerList[i]= el.answerList[i]+'@'+el.answerList[i+1]
                  el.answerList[i]=   el.answerList[i].split('@')

                  el.answerList.splice(i+1,1)
                }
              })
            }

        });
      }else{
        this.$message.error(res.message)

      }
    },
    getRowKey(row) {
      return row.id
    },
    //排序
    changeSort(val) {
      //val里面返回prop和order
      if (val.prop == 'createTime') {
        if (val.order == 'ascending') {
          //updateAt列升序时

          this.queryInfo.orderByParam = 't.create_time asc'
          this.search()
        } else if (val.order == 'descending') {
          //updateAt列降序时

          this.queryInfo.orderByParam = 't.create_time desc'
          this.search()
        } else {
          //updateAt列不排序时
          this.queryInfo.orderByParam = 't.create_time desc'
          this.search()
        }
      }
    },

    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
      // console.log(`当前页: ${val}`)
    },
    // 搜索
    changeSearchangeSearch() {
      this.queryInfo.pageNum = 1
      this.search()
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  height: 80vh;
  overflow: scroll;
}
  /deep/ .el-radio-button__inner {
    border: none;
    margin-right: 12px;
    border-radius: 4px;
  }
  .quertion_type{
    display: inline-block;
    padding:4px 6px;
    background: #fdddcc;
    color: #f7894c;
    font-size: 12px;
    border-radius: 4px;
  }
.seach_box {
  display: flex;
  margin: 15px 0;

  .input-with-select {
    width: 400px;
  }

  .seach {
    background-color: #448aff;
    color: #fff;
    border-radius: 0;
    border: 1px solid #448aff;
  }
}
.office {
  display: inline-block;
  background: #edf4ff;
  border: 1px solid #559eff;
  color: #4e93fb;
  border-radius: 2px;
  font-size: 12px;
  padding: 0px 3px;
}
.span_div {
  font-size: 14px;
  color: #7c7f8e;
  margin-right: 12px;
}

.input-with {
  width: 100%;
  overflow: hidden;

  .input-with-select {
    width: 400px;
    float: right;
  }

  .seach {
    background-color: #448aff;
    color: #fff;
    border-radius: 0;
    border: 1px solid #448aff;
  }
}

.in_table {
  width: 100%;
  margin-top: 20px;

  .img_url {
    width: 42px;
    height: 42px;
    border-radius: 4px;
  }
}
.edit {
  padding-left: 20px;
  color: #4e93fb;
  cursor: pointer;
}
.frozen {
  padding-left: 20px;
  color: #fd5469;
  cursor: pointer;
}

.new_page {
  margin: 20px auto;
  width: 100%;
  text-align: center;
}
.dialogVisible {
  padding-left: 30px;
  .dialogVisible_top {
    .username_phone {
      padding-bottom: 10px;
      font-size: 18px;
      color: #212121;
      font-weight: 550;
      .feed_center {
        margin-left: 10px;
        margin-right: 10px;
        position: relative;
        top: 2px;
        display: inline-block;
        width: 0px;
        height: 14.5px;
        border: 2px solid #d4d4d4;
      }
    }
  }
  .feed_info {
    .feed_info_cont {
      margin-top: 20px;
      .caption {
        width: 340px;
        font-size: 18px;
        color: #212121;
      }
      .answer {
        color: #212121;
        .annex_flex {
          display: flex;
          padding: 5px;
          width: 322px;
          border: 1px solid #efefef;
          cursor: pointer;
          .annex_name {
            margin-left: 5px;
            width: 300px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .order_num{
          display: inline-block;
          width: 16px;
          height: 16px;
          background: #4e93fb;
          color: #fff;
          text-align: center;
          line-height: 16px;
          font-size: 12px;
          margin-right: 10px;
        }
        .did_not{
          margin-top: 20px;
          padding: 20px;
          background: #fafafa;
          color: #8B8B8B;
          .option{
            color: #929292;
            padding-bottom: 10px;
          }
          .not_num{
            display: inline-block;
          width: 16px;
          height: 16px;
          background: #929292;
          color: #fff;
          text-align: center;
          line-height: 16px;
          font-size: 12px;
          margin-right: 10px;
          }
        }

      }
    }
  }
}
</style>
